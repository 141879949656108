$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #ecf0f1 !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #b4bcc2 !default;
$gray-600: #95a5a6 !default;
$gray-700: #7b8a8b !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$blue:   #2C3E50 !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink:   #e83e8c !default;
$red:    #E74C3C !default;
$orange: #fd7e14 !default;
$yellow: #F39C12 !default;
$green:  #18BC9C !default;
$teal:   #20c997 !default;
$cyan:   #3498DB !default;

$primary:   $blue !default;
$secondary: $gray-600 !default;
$success:   $green !default;
$info:      $cyan !default;
$warning:   $yellow !default;
$danger:    $red !default;
$light:     $gray-200 !default;
$dark:      $gray-700 !default;

$yiq-contrasted-threshold:  175 !default;

// Recognizable links
$link-color: navy;
$link-decoration: none;
$link-hover-decoration: underline;

// Fonts
$font-size-base: 1rem !default;
$h1-font-size: 2rem !default;
$h2-font-size: 1.5rem !default;
$h3-font-size: 1.25rem !default;

// Navs
$nav-link-padding-y: .2rem !default !default;
$nav-link-padding-x: 1rem !default;
$navbar-padding-y: 1rem !default;
$navbar-padding-x: .625rem !default;

// Border radius
$border-radius: 0px !default;
$border-radius-lg: 0px !default;
$border-radius-sm: 0px !default;
$card-inner-border-radius: 0px !default;

$toast-max-width: 100%;

.text-black-70 {
  color: $gray-700 !important;
}

.text-black-80 {
  color: $gray-800 !important;
}

.text-black-90 {
  color: $gray-900 !important;
}

@import "../node_modules/bootstrap/scss/bootstrap";
